import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Doctolib from "../components/doctolib"
import "../styles/global.css"
import Conseils from "../components/conseils"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title={data.site.siteMetadata.title} />

    <Container className="container">
      <div style={{ position: "relative" }}>
        <Img
          fixed={data.file.childImageSharp.fixed}
          style={{ maxHeight: 300, width: "100%", objectFit: "cover" }}
        />
        <div className="header-doctolib">{<Doctolib />}</div>
      </div>

      <h1>L'Ostéopathie, pour qui, pourquoi ? </h1>
      <div className="container-wrap">
        {/* je boucle sur les markdown avec condition d'affichage type === page */}
        {data.allMarkdownRemark.edges.map(({ node }) =>
          node.frontmatter.type === "page" ? (
            <div
              key={node.id}
              style={{
                width: "250px",
                height: "250px",
                position: "relative",
                textAlign: "center",
                margin: "0.5em",
              }}
            >
              <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  color: "white",
                  fontSize: "2.5em",
                  fontWeight: "lighter",
                }}
              >
                {node.frontmatter.title}
              </div>
              <Link
                to={node.frontmatter.slug}
                style={{
                  position: "absolute",
                  top: "85%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  color: "white",
                  fontSize: "0.8em",
                  backgroundColor: "#6CBA40",
                  paddingRight: "1em",
                  paddingLeft: "1em",
                  borderRadius: "20px",
                }}
              >
                En savoir plus
              </Link>
            </div>
          ) : null
        )}
      </div>
      <Conseils />

      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      ></div>
    </Container>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "10vw",
        paddingBottom: "10vw",
      }}
    >
      {<Doctolib />}
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "mains_ostheopate.jpg" }) {
      childImageSharp {
        fixed(width: 1920, height: 1280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            description
            type
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
